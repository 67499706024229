import { defineStore, skipHydrate } from 'pinia';
import type {
  TCaseDropItem,
  TItemCaseData,
  ISimplifiedPreviewItem,
  ITypeDescriptionCase,
} from '~/features/cases/types/case.types';
import type { Dto } from '~/repository/modules/cases';
import { ETypeCases, typesCaseDescription } from '~/features/cases/constants/case.constants';
import type {
  IAvailableProgressCase,
  ICaseData,
  ICaseOffer,
  IImageData,
  IOpenItems,
  ISection,
  ISwitchCaseData,
  TChallangeData,
  TTestyCoins,
} from '~/repository/modules/cases/cases.types';
import type { TPossibleNull } from '~/types/Shared.types';
import MessageError from '~/api/global/errors/messages/MessageError';
import { useAlertStore } from '~/store/alert/alert.store';
import { useFinalDropsStore } from '~/features/cases/store/useFinalDrops.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useCarouselStore } from '~/features/cases/store/useCarousel.store';
import { useUserStore } from '~/store/user/user.store';
import { useDropWinnersStore } from '~/features/cases/store/dropWinners.store';
import { useVolumeStore } from '~/store/volume/volume';
import { normalizeCaseObject } from '~/features/cases/utils/normalizeCaseObject';
import { CaseEvents } from '~/repository/amplitude/events/case';
import { Currency } from '~/constants/currency.constants';
import type { ICaseEnteredEvent } from '~/repository/amplitude/types/case.types';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const useCaseStore = defineStore('cases/case', () => {
  // import
  const {
    $api: { cases: CaseApi },
    $i18n: { t },
  } = useNuxtApp();

  const { isChallengesDisabled } = useProjectSettings();

  const isLoaded = ref(false);

  const { accessToken } = storeToRefs(useAuthStore());

  const alertStore = useAlertStore();
  const finalDropsStore = useFinalDropsStore();
  const carouselStore = useCarouselStore();
  const userStore = useUserStore();
  const dropsWinners = useDropWinnersStore();
  const volumeStore = useVolumeStore();
  const { dropsWinner, dropWinnerTastyCoins } = storeToRefs(dropsWinners);
  const { resetDropsWinner } = dropsWinners;

  const isCaseOpenInProcess = ref<boolean>(false);

  // массив всех кейсов
  const allCases = ref<ISection[]>([]);

  const caseData = ref<TItemCaseData>();
  // Фон страницы кейса
  const caseBg = ref<IImageData>({} as IImageData);
  // используется для задачи имени из роута
  const caseName = ref<string>('');
  // сведения о прогрессе для информера в ивентных кейсах
  const progressData = ref<TPossibleNull<TChallangeData>>(null);
  // формат отображения описания кейсов в зависимости от типа
  const activeTypeDescriptionCase = ref<ITypeDescriptionCase>();
  // сведения о наличии коинов в кейсе - критерий является ли ивентным
  const caseCoins = ref<TPossibleNull<TTestyCoins>>(null);

  // модель для отслеживания ошибки открытия кейса
  const isCaseOpenSuccess = ref<boolean>(true);

  const caseMultiplier = ref<number>(1);
  const caseType = ref<ETypeCases>(ETypeCases.CASE);

  const enoughMoneyCaseLoading = ref(true);
  const eventCasesLoading = ref(true);
  const challengeProgressLoading = ref(true);

  // уровень на котором будет доступен бесплатный кейс
  const requiredLevel = computed(() => caseData.value?.additionalProperties?.dailyCaseProperties?.level ?? 0);

  // Время до того, как можно будет заново открывать кейс (24 часа после открытия)
  const timeUntilOpenDailyCase = computed<number>(() => caseData.value?.counterData?.end ?? Date.now());

  // Прошло ли 24 часа после последнего открытия кейса
  const isDailyCaseAvailableByTime = computed<boolean>(
    () => !timeUntilOpenDailyCase || timeUntilOpenDailyCase.value <= 0,
  );

  // Время до открытия бесплатного кейса в минутах
  const differenceUntilOpenInMinutes = computed<number>(() => {
    const difference = new Date(timeUntilOpenDailyCase.value).getTime() - new Date().getTime();
    return difference / (1000 * 60);
  });

  // Является ли кейс бесплатным
  const isDailyCase = computed<boolean>(() => caseData.value?.isDaily ?? false);

  // Выполнены ли условия открытия бесплатного кейса
  const fulfilledConditions = computed<boolean>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties?.openConditions?.conditionsFulfilled ?? false,
  );

  // Можно ли открыть бесплатный кейс
  const canOpenDailyCase = computed<boolean>(() => {
    return (
      isDailyCaseAvailableByTime.value &&
      fulfilledConditions.value &&
      isDailyCaseAvailableByLevel.value &&
      !!userStore.user?.wasGeneratedLvl
    );
  });

  // Доступный оффер
  const availableOffer = computed<ICaseOffer | null>(() => caseData.value?.offers?.[0] ?? null);

  // Сумма для пополнения для выполнения условия открытия бесплатного кейса
  const replenishmentSum = computed<number>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties?.openConditions?.depositAmount ?? 0,
  );

  // Количество дней за которые необходимо пополнить баланс
  const replenishmentDays = computed<number>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties?.openConditions?.depositDays ?? 0,
  );

  // Сумма на которую осталось пополнить баланс за n дней если он пополнил не на всю необходимую сумму
  const replenishmentMissingSum = computed<number>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties?.openConditions?.amountLeft ?? 0,
  );

  // Является ли кейс первым по уровню
  const isFirstCase = computed<boolean>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties?.prevCase?.name === undefined,
  );

  const nextCase = computed<ISwitchCaseData | null>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties.nextCase ?? null,
  );
  const prevCase = computed<ISwitchCaseData | null>(
    () => caseData.value?.additionalProperties?.dailyCaseProperties.prevCase ?? null,
  );

  const multiCasePrice = computed<number>(() => {
    if (!caseData.value || !caseData.value.priceData || !caseData.value.priceData.price) return 0;
    return +caseData.value.priceData.price;
  });

  /* ----------------------------------------- Computeds - определяющие характеристики кейса ⬇️ -------------------------------  */

  // Является ли кейс коин кейсом
  const isCoinCase = computed<boolean>(() => caseData.value?.priceData.currency === Currency.TASTY_COIN);

  // Является ли кейс оффер кейсом
  const isOfferCase = computed<boolean>(() => caseData.value?.modifiers?.isOffer ?? caseData.value?.isOffer ?? false);

  // Можно ли открыть оффер кейс
  const canOpenOfferCase = computed<boolean>(() => caseData.value?.modifiers?.isCanOpenOffer ?? false);

  // На каком уровне battlePass кейс можно открыть бесплатно
  const levelFreeOpenBattlePass = computed<number>(() => caseData.value?.modifiers?.levelOpen ?? 0);

  // Доступен ли кейс по уровню челленджа
  const isCaseAvailableByChallengeLevel = computed(
    () => (progressData.value?.currentEventLevel ?? 0) >= levelFreeOpenBattlePass.value,
  );

  // Достигнут ли максимальный уровень по battlePass
  const hasBattlePassMaxLevel = computed<boolean>(() => progressData.value?.isFinished ?? false);

  // Можно ли открыть кейс бесплатно
  const isFreeOpen = computed<boolean>(() => isOfferCase.value && isCaseAvailableByChallengeLevel.value);

  // Доступен ли коин кейс по цене
  const hasEnoughCoinsToOpenCase = computed<boolean>(
    () => +(userStore.user?.finance.coinBalance ?? 0) >= multiCasePrice.value,
  );

  // Доступен ли коин кейс по цене с учетом доступности по челенджу
  const isCoinCaseAvailableByPrice = computed<boolean>(() => {
    return isCaseAvailableByChallengeLevel.value && hasEnoughCoinsToOpenCase.value;
  });

  // Показывать ли блок и с подсказками по получению коинов в коин кейсе
  const showCoinCaseEventBlock = computed<boolean>(
    () => isCoinCase.value && (!hasEnoughCoinsToOpenCase.value || !isCaseAvailableByChallengeLevel.value),
  );

  // находится ли кейс в Battle Pass
  const isInBattlePass = computed<boolean>(() => caseData.value?.isBattlePass ?? false);

  // Показывать ли ивентный блок в недоступном офферном кейсе
  const showUnavailableOfferCaseEventBlock = computed<boolean>(
    () => (isOfferCase.value && isInBattlePass.value) || isEventCase.value,
  );

  const buttonsGroupDataLoaded = computed<boolean>(
    () => !enoughMoneyCaseLoading.value && !challengeProgressLoading.value,
  );

  // Доступный офферный кейс для редиректа
  const availableProgressOfferCase = computed<IAvailableProgressCase | null>(
    () => caseData.value?.availableProgressCase ?? null,
  );

  // Доступен ли кейс
  const isCaseAvailableByPrice = computed<boolean>(() => {
    if (isDailyCase.value) return canOpenDailyCase.value;
    if (isOfferCase.value) return canOpenOfferCase.value;
    if (isCoinCase.value) return isCoinCaseAvailableByPrice.value;
    if (!userStore.user || !userStore.userBalance) return false;
    return +userStore.userBalance - multiCasePrice.value >= 0;
  });

  // Доступен ли бесплатный кейс по уровню пользователя
  const isDailyCaseAvailableByLevel = computed(() => {
    return Number(userStore.user?.userLevel) >= Number(requiredLevel.value);
  });

  // Есть ли другие доступные офферные кейсы
  const hasOtherOfferCases = computed<boolean>(() => {
    return allCases.value.some((section) => {
      return section.cases.some((caseItem) => caseItem.modifiers?.isOffer && caseItem.modifiers?.isCanOpenOffer);
    });
  });

  // Оффер кейса
  const caseOffer = computed<ICaseOffer | null>(
    () => caseData.value?.offers?.sort((a, b) => b.price - a.price)[0] ?? null,
  );

  /* ----------------------------------------- Computeds - определяющие характеристики кейса ⬆️ -------------------------------  */

  const isQuickOpening = ref(false);

  const eventCases = ref<TItemCaseData[]>([]);
  const enoughMoneyCase = ref<TItemCaseData>();

  const ultraItems = ref<ISimplifiedPreviewItem[]>([]);
  const otherItems = ref<ISimplifiedPreviewItem[]>([]);

  // Время входа на страницу кейса - для аналитики
  const startTimeThisCase = ref<Date | null>(null);

  const caseBackground = computed<NonNullable<object>>(() => {
    return caseData.value?.imageData || {};
  });

  const isShowSoldAllThingsBtn = computed<boolean>(
    () => isCaseAvailableByPrice.value && multiCasePrice.value <= +(userStore.user?.finance.totalAmountItems || 0),
  );

  const setCaseName = (name: string) => {
    caseName.value = caseData?.value ? caseData?.value?.name : name;
  };

  /**
   * Получает базовый кейс по названию
   * @param {string} caseName - наименование кейса
   * @returns {Promise<void>}
   */
  const fetchCase = async (caseName: string): Promise<void> => {
    if (!caseName) return;
    isLoaded.value = false;

    try {
      const response: TPossibleNull<Dto.CaseResponseDto> = await CaseApi.getCase(caseName);

      if (!response) return;
      if (!response.caseData) throw new Error('No case data fetched');
      if (!response.dropList) throw new Error('No case items fetched');

      caseData.value = normalizeCaseObject(response, t);

      caseMultiplier.value = response.caseData.multicastData?.count || response.caseData.multifix || 1;
      fillItems(response.dropList);
      defineCaseType(response.caseData);
      caseBg.value = response?.caseData?.imageData || ({} as IImageData);
      if (typeof response?.caseData?.tastyCoins !== 'undefined') caseCoins.value = response?.caseData?.tastyCoins;
      carouselStore.setCarousels(response.dropList);
      // Изначально устанавливаем в качестве выигранного предмета самый дешевый
      carouselStore.setCheapestItemAsWonDrop(response.dropList);
      setActiveTypeCase();
    } catch (e) {
      alertStore.showError({
        message: (e as { msg?: string }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoaded.value = true;
    }
  };

  const getOpenProfit = (sum: number, price: number) => {
    const delta = sum - price;

    return {
      money: Math.floor(delta),
      percent: Math.floor((delta / price) * 100) + '%',
    };
  };

  const getDefaultAmplitudeData = () => {
    if (!caseData.value) return;

    const { id, label, type, priceData, tastyCoins } = caseData.value;
    const parsedPrice = +priceData.price;

    return {
      'Case Currency': priceData.currency,
      'Case ID': id,
      'Case Name': label,
      'Case Price': parsedPrice,
      'Case Type': type || 'case',
      'Is Free Case': parsedPrice === 0,
      'Is Giving Coins': tastyCoins !== null,
    } as ICaseEnteredEvent;
  };

  const openCase = async (caseName: string, isQuick: boolean) => {
    resetDropsWinner();
    try {
      const openedCase: TPossibleNull<IOpenItems> = await CaseApi.openCase(caseName);
      if (!openedCase) throw MessageError.DataNotFound;

      const { items, tastyCoins, prizeSegment } = openedCase;
      if (!items) throw MessageError.DataNotFound;

      const amplitudeData = getDefaultAmplitudeData();
      if (amplitudeData && caseData.value) {
        const { priceData } = caseData.value;
        const totalItemsSum = items.reduce((acc, item) => {
          acc += +item.dropItemData.priceData.price;
          return acc;
        }, 0);

        if (isDailyCase.value) {
          amplitudeData['Case Type'] = 'Free Daily Case';
        }

        const { money: rubProfit, percent: percentProfit } = getOpenProfit(totalItemsSum, +priceData.price);

        CaseEvents.caseOpened({
          ...amplitudeData,
          'Fast Open': isQuick,
          'Is Sound Enabled': !volumeStore.isMuteCookie,
          'User Profit in Percent': percentProfit,
          'User Profit in RUB': rubProfit,
        });
      }

      finalDropsStore.setFinalDrop(items);

      finalDropsStore.setTastyCoins(tastyCoins || 0);
      finalDropsStore.setPrizeSegment(prizeSegment);

      dropsWinner.value = items || [];
      dropWinnerTastyCoins.value = tastyCoins;
      caseMultiplier.value = items?.length;

      if (caseData.value?.multicastData?.count || caseData.value?.multifix) {
        carouselStore.cloneCarousels(caseMultiplier.value);
      }

      // Пробегаем по всем выигранным предметам - 1 предмет = 1 рулетка

      items.forEach((_, idx) => carouselStore.appendWinnerItem(idx, items[idx].dropItemData));
      return true;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: t('alerts.errorOpeningCase'),
      });
      isCaseOpenSuccess.value = false;
      isCaseOpenInProcess.value = false;
      carouselStore.cloneCarousels(1);
      return false;
    }
  };

  const fetchEventCases = async (count?: number) => {
    eventCasesLoading.value = true;
    try {
      const cases: ICaseData[] | undefined = await CaseApi.getEventCases(count);
      eventCases.value = cases || [];
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      eventCasesLoading.value = false;
    }
  };

  const fetchEnoughUserMoneyCase = async () => {
    if (!accessToken.value) return;
    enoughMoneyCaseLoading.value = true;
    try {
      let response = await CaseApi.getMaxCasePriceForUser();
      if (Array.isArray(response) && !response.length) response = response[0];
      enoughMoneyCase.value = response;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      enoughMoneyCaseLoading.value = false;
    }
  };

  const fetchChallengeProgress = async (caseName?: string) => {
    if (!accessToken.value) return;
    if (isChallengesDisabled) {
      challengeProgressLoading.value = false;
      return;
    }
    challengeProgressLoading.value = true;
    try {
      progressData.value = await CaseApi.getChallangeProgress(caseName);
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      challengeProgressLoading.value = false;
    }
  };

  const isEventCase = computed<boolean>(() => {
    return caseCoins.value !== null;
  });

  // TODO избавиться
  const fetchSections = async (): Promise<void> => {
    try {
      const data = await CaseApi.getSections();

      if (data) {
        allCases.value = data;
      }
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
      const localeRoute = useLocaleRoute();
      navigateTo(localeRoute('/'));
    }
  };

  function defineCaseType(caseData: TItemCaseData) {
    if (caseData.multifix) caseType.value = ETypeCases.MULTIFIX;
    else if (caseData.multicastData?.count) caseType.value = ETypeCases.MULTICAST;
    else if (caseData.labelData.freeOpens !== null) caseType.value = ETypeCases.N_FREE;
    else if (caseData.labelData?.type === 'new') caseType.value = ETypeCases.NEW;
    caseType.value = ETypeCases.CASE;
  }

  function fillItems(allItems: TCaseDropItem[] = []) {
    const sortedAscItems = allItems
      .map(({ id, name, qualityEnum, priceData, imageData, isUltraRare, type, packItems, parentPack }) => ({
        id,
        image: imageData.image,
        isUltraRare,
        name,
        priceData,
        quality: qualityEnum.name,
        type,
        packItems,
        parentPack,
      }))
      .sort((a, b) => +b.priceData.price - +a.priceData.price) as ISimplifiedPreviewItem[];

    ultraItems.value = [];
    otherItems.value = [];

    for (const sortedItem of sortedAscItems) {
      const isUltraItemInArray = ultraItems.value.find((item) => item.id === sortedItem.id);
      if (sortedItem.isUltraRare && !isUltraItemInArray) {
        ultraItems.value.push(sortedItem);
        continue;
      }
      const isItemInArray = otherItems.value.find((item) => item.id === sortedItem.id);
      if (isItemInArray || sortedItem.isUltraRare) continue;
      otherItems.value.push(sortedItem);
    }
  }

  // Метод хелпер, опеределяет тип активного кейса
  const setActiveTypeCase = (): void => {
    if (!caseData.value) return;
    if (caseData.value.multifix) {
      activeTypeDescriptionCase.value = typesCaseDescription.multifix;
      return;
    }

    if (caseData.value.multicastData?.count) {
      activeTypeDescriptionCase.value = typesCaseDescription.multicast;
    }
  };

  const clearItemCase = () => {
    caseData.value = undefined;
  };

  /** Метод вычисления значений для аналитики **/
  const calculateStandEventAmplitude = () => {
    if (!startTimeThisCase.value) return;
    const lastDate = new Date();
    const outTimeThisCase = Math.floor((+lastDate - +startTimeThisCase.value) / 1000);

    const amplitudeData = getDefaultAmplitudeData();
    if (!amplitudeData) return;

    CaseEvents.caseLeave({
      ...amplitudeData,
      'Case Out': outTimeThisCase,
    });
  };

  const sendCaseEnteredAmplitude = () => {
    startTimeThisCase.value = new Date();

    let amplitudeData = getDefaultAmplitudeData();
    if (!amplitudeData || !caseData.value) return;
    if (isDailyCase.value) {
      amplitudeData = {
        ...amplitudeData,
        'Case Type': 'Free Daily Case',
        'Source': 'Free Cases Page',
      };
    }

    const { id } = caseData.value;
    const categoryObj = allCases.value.find(({ cases }) => cases.find(({ id: caseId }) => caseId === id));

    CaseEvents.caseEntered({
      ...amplitudeData,
      Category: categoryObj?.name || '',
    });
  };

  return {
    activeTypeDescriptionCase,
    allCases,
    availableProgressOfferCase,
    availableOffer,
    calculateStandEventAmplitude,
    canOpenOfferCase,
    caseBackground,
    caseBg,
    caseData,
    eventCasesLoading,
    caseMultiplier,
    caseName,
    caseOffer,
    caseType,
    clearItemCase,
    differenceUntilOpenInMinutes,
    enoughMoneyCase,
    eventCases,
    fetchCase,
    fetchChallengeProgress,
    fetchEnoughUserMoneyCase,
    fetchEventCases,
    fetchSections,
    hasBattlePassMaxLevel,
    hasOtherOfferCases,
    isCaseAvailableByChallengeLevel,
    fulfilledConditions,
    isCaseAvailableByPrice,
    isCaseOpenInProcess,
    isCaseOpenSuccess,
    isCoinCase,
    isDailyCase,
    isDailyCaseAvailableByLevel,
    isDailyCaseAvailableByTime,
    isEventCase,
    isFirstCase,
    isFreeOpen,
    isInBattlePass,
    isLoaded,
    isOfferCase,
    isQuickOpening,
    isShowSoldAllThingsBtn,
    levelFreeOpenBattlePass,
    multiCasePrice,
    nextCase,
    openCase,
    otherItems: skipHydrate(otherItems),
    prevCase,
    progressData,
    replenishmentDays,
    replenishmentMissingSum,
    replenishmentSum,
    requiredLevel,
    sendCaseEnteredAmplitude,
    setActiveTypeCase,
    setCaseName,
    timeUntilOpenDailyCase,
    showCoinCaseEventBlock,
    showUnavailableOfferCaseEventBlock,
    buttonsGroupDataLoaded,
    ultraItems: skipHydrate(ultraItems),
  };
});
