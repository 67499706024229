export enum ECaseEvents {
  CASE_ENTERED = 'Case - Case Entered',
  CASE_LEAVE = 'Case - Case Leave',
  CASE_OPENED = 'Case - Case Opened',
}

export interface ICaseEnteredEvent {
  'Case Currency': string;
  'Case ID': number;
  'Case Name': string;
  'Case Price': number;
  'Case Type': string;
  'Category': string;
  'Is Free Case': boolean;
  'Is Giving Coins': boolean;
  'Source'?: string;
}

export interface ICaseOpenedEvent {
  'Case Currency': string;
  'Case ID': number;
  'Case Name': string;
  'Case Price': number;
  'Case Type': string;
  'Fast Open': boolean;
  'Is Free Case': boolean;
  'Is Giving Coins': boolean;
  'Is Sound Enabled': boolean;
  'User Profit in Percent': string;
  'User Profit in RUB': number;
}

export interface ICaseLeaveEvent {
  'Case Currency': string;
  'Case ID': number;
  'Case Name': string;
  'Case Out': number;
  'Case Price': number;
  'Case Type': string;
  'Is Free Case': boolean;
  'Is Giving Coins': boolean;
}
