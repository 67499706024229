import type { ComposerTranslation } from '#i18n';
import type { Dto } from '~/repository/modules/cases';
import { ECasesCounterTypes } from '~/features/mainPage/Types/cases/client.types';
import { getLabelData } from '~/features/mainPage/Types/cases/adapter';
import type { TItemCaseData } from '~/features/cases/types/case.types';

export const normalizeCaseObject = (dataObject: Dto.CaseResponseDto, t: ComposerTranslation): TItemCaseData => {
  return {
    ...dataObject.caseData,
    additionalProperties: dataObject.additionalProperties,
    availableProgressCase: dataObject.availableProgressCase,
    isBattlePass: dataObject.isBattlePass,
    counterData: {
      end: dataObject.additionalProperties?.dailyCaseProperties?.counterEnd ?? 0,
      start: dataObject.additionalProperties?.dailyCaseProperties?.counterStart ?? 0,
      type: ECasesCounterTypes.TIME,
    },
    modifiers: {
      isCanOpenOffer: dataObject.isCanOpenOffer,
      isOffer: dataObject.caseData.isOffer ?? false,
      levelOpen: dataObject.levelOpen,
    },
    isDaily: dataObject.isDaily,
    labelData: { ...dataObject.caseData.labelData, ...getLabelData(dataObject.caseData, t) },
    offers: dataObject.offers,
  };
};
